body {
  margin: 0 !important;
  padding: 0;
  font-family: sans-serif;
  background: #ddd;
}
.react-swipeable-view-container {
  max-height: calc(100vh - 140px);
}
#webCT {
  background-color: #fff;
}
#webCT * {
  font-family: Arial, Helvetica, sans-serif;
}
#webCT a {
  cursor: pointer;
}
.tpanel::-webkit-scrollbar {
  display: none;
}
#loading > div:nth-child(2) {
  background-color: transparent;
  box-shadow: none;
}
div.nowrap {
  white-space: nowrap;
}
.MuiTab-root-94 {
  max-width: 100% !important;
}
#menu-appbar > div:nth-child(2) {
  top: 0px !important;
}
.col-xs4-12.col-sm-6.col-md-6.col-lg-4.col-xl-3 {
  width: calc(100% - 8px);
}
.MuiBadge-badge-108 {
  font-size: 9px !important;
}
/* .switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
} 
.col-xs4-12.col-sm-6.col-md-6.col-lg-4.col-xl-3 {
  width: calc(100% - 8px);
}*/
*:focus {
  outline: none;
}
.rectpag {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  left: 0;
  right: 0;
  background-color: #fff;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 5px 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #428bca;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #2a6496;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #428bca;
  border-color: #428bca;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}
.pager li {
  display: inline;
}
.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}
.pager li > a:hover,
.pager li > a:focus {
  text-decoration: none;
  background-color: #eee;
}
.pager .next > a,
.pager .next > span {
  float: right;
}
.pager .previous > a,
.pager .previous > span {
  float: left;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.search-input {
  position: relative;
  padding-top: 10px;
}

.search-input::before {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==");
  display: block;
  position: absolute;
  width: 15px;
  z-index: 3;
  height: 15px;
  font-size: 20px;
  top: 11px;
  left: 6px;
  line-height: 32px;
  opacity: 0.6;
}

.search-input > input {
  width: 100%;
  font-size: 18px;
  border: none;
  line-height: 22px;
  padding: 5px 10px 5px 25px;
  height: 32px;
  position: relative;
}

.search-input > input:focus {
  outline: none;
}
.tabCTdiv {
  height: calc(100vh - 230px);
  padding: 10px;
  background-color: #fff;
  width: calc(100% - 40px);
}

/*Web piaotian */
#webCT .h_link {
  width: auto !important;
}
#fengtuiword {
  overflow: auto;
}
.blocktitle .txt {
  max-width: calc(100% - 40px);
}
.nav .navinner a,
.nav a:visited {
  color: #fff;
  text-decoration: none;
}
.cbook {
  margin-bottom: 20px;
}
/*het piaotian*/
/*UUkanshu*/
.zhengwan_top,
.hot-recommend,
.paihang li,
.w_main .h1title {
  height: auto !important;
}
#webCT .nav {
  height: 70px !important;
}
.weizhi .path {
  width: auto !important;
}
#timu {
  display: block;
  max-width: 1000px !important;
  margin-left: 0 !important;
  text-align: center;
}
.fanye_cen {
  width: 500px;
}
/*End uukanshu*/
/*qidian*/
#webCT .main-nav.box-center.cf {
  width: 1240px;
}
body {
  width: auto !important;
}
/*endqidian*/
div.bookinfo .react-swipeable-view-container {
  max-height: inherit;
}
@media only screen and (max-width: 599px) {
  .tabCTdiv {
    height: calc(100vh - 290px);
  }
  .search-input,
  .bookpage {
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    width: 100%;
  }
  #listchap {
    height: auto !important;
    overflow: unset !important;
    padding: 50px 0 !important;
  }
  .bookpage {
    bottom: 0;
  }
  .tpanel {
    height: auto !important;
  }
  .react-swipeable-view-container {
    max-height: calc(100vh - 165px);
  }
  .cardBook {
    min-height: calc(100vh - 150px) !important;
  }
  div.bookinfo .react-swipeable-view-container {
    max-height: calc(100vh - 110px);
  }
  .cbook {
    margin-bottom: 10px;
  }
  .h2Chap {
    font-size: 20px;
  }
}
#root button[class*="MuiTab-root-"] {
  overflow: inherit !important;
}
.tabCTdiv.ttdiv {
  overflow-y: auto;
}
.ttdiv * {
  background-color: transparent !important;
}
.btnFacebook { 
  width: 98%;
  height:40px;  
  border-radius: 4px;
  background: #3b5998;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  display: inline-block;
  font-weight: bold
}
.btnGoogle {
  margin: 5px;
  width: 98%;
  border-radius: 4px!important;
  color: #000!important;
  text-align: center;
  font-weight: bold!important;
}
.btnGoogle span{
  font-weight: bold!important;
}
.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.6;
}
.btnGoogle:hover {
  background: #db3236;
  opacity: 0.6;
}
/*chinh css web nguon cac trang*/
.ad_content {
  display: none;
}
.box_con #content, .box_con #content div, .box_con #content a,#webCT #content {
  font-family: arial!important;
  letter-spacing: unset!important;
  text-align: justify;
}
#webCT div#amain #contents {
  line-height: 1.3;
  font-size: 24px;
}
.reader_box .content p {
  font-size: 20px;
}
#webCT #mainbox #content,.d_contarin .d_cont .nr_con .content,#directs .bookInfo .mainContenr {
  line-height: 1.2;
  font-size: 20px;
  background-image: unset;
}